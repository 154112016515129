import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"

import { Container } from "@material-ui/core"
import { Layout } from "../components/layout"
import PortfoliosCardGrid from "../components/PortfoliosTemplate/PortfolioCardGrid"
import { PartnerGrid } from "../components/PartnerGrid"
import { Sepcialisms } from "../components/PortfoliosTemplate/specialisms"
import { ToggleButton } from "../components/Buttons/ToggleButton"
import styled from "styled-components"
import { ButtonSecondary } from "../components/Buttons/Buttons"
import RightArrow from "../assets/svg/long-arrow-right.svg"
const Search: React.FC = ({ data }) => {
  const partners = data.allWpPartner.edges
  const partnersNode = data.allWpPartner.edges.map(v => v.node)
  const universitiesData = data.allWpUniversity.edges.map(v => v.node)
  const portfoliosData = data.allWpPortfolio.edges.map(v => v.node)
  const [studentNames, setStudentNames] = useState([])
  const [searchList, setSearchList] = useState([])
  const [portfoliosToRender, setPortfoliosToRender] = useState([])
  const [graduationYearsToRender, setGraduationYearsToRender] = useState([])
  const [partnersToRender, setPartnersToRender] = useState([])
  const [universitiesToRender, setUniversitiesToRender] = useState([])
  const [specialismsToRender, setSpecialismsToRender] = useState([])
  const [isPortfoliosToggle, setIsPortfliosToggle] = useState(true)
  const [isFullView, setIsFullView] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState("")
  const [typedSearch, setTypedSearch] = useState("")
  const [toggleCategory, setToggleCategory] = useState({ partnerships: true, portfolios: true })

  const year = new Date().getFullYear()
  const studentYears = [year, year - 1, year - 2]

  const handleUpdate = event => {
    setTypedSearch(event.target.value.toLowerCase())
  }

  useEffect(() => {
    let mount = true

    if (mount) {
      const studentspecs = []
      const partnerspecs = []
      const unispecs = []
      const specs = []
      const graduationYears = []

      if (typedSearch === "") {
        setPartnersToRender(partnerspecs)
        setPortfoliosToRender(studentspecs)
        setUniversitiesToRender(unispecs)
        setSpecialismsToRender(specs)
        setGraduationYearsToRender(graduationYears)
        return
      }

      searchList.map((item, index) => {
        try{
        if (item.group == "Student") {
          // console.log(portfoliosData[item.originalIndex])
          if (
            portfoliosData[item.originalIndex].student_profile.specialism.includes(typedSearch) ||
            portfoliosData[item.originalIndex].student_profile.studentName.toLowerCase().includes(typedSearch)
          ) {
            studentspecs.push(portfoliosData[item.originalIndex])
          }
        } else if (item.group == "Partner") {
          if (
            partnersNode[item.originalIndex].PartnerInfo.tags.includes(typedSearch) ||
            partnersNode[item.originalIndex].title.toLowerCase().includes(typedSearch)
          ) {
            partnerspecs.push(partners[item.originalIndex])
          }
        } else if (item.group == "University") {
          if (universitiesData[item.originalIndex].title.toLowerCase().includes(typedSearch)) {
            unispecs.push(universitiesData[item.originalIndex])
          }
        } else if (item.group == "Specialisms") {
          if (Sepcialisms[item.originalIndex].toLowerCase().includes(typedSearch)) {
            specs.push(Sepcialisms[item.originalIndex])
          }
        } else if (item.group == "Graduation Year") {
          if (studentYears[item.originalIndex].toString().includes(typedSearch)) {
            graduationYears.push(studentYears[item.originalIndex])
          }
        }
      }catch(e){
        // console.log(e)
      }
      })
      setPartnersToRender(partnerspecs.map(v => v.node))
      setPortfoliosToRender(studentspecs)
      setUniversitiesToRender(unispecs)
      setSpecialismsToRender(specs)
      setGraduationYearsToRender(graduationYears)
    }
    return () => {
      mount = false
    }
  }, [typedSearch])

  useEffect(() => {
    let mount = true
    if (mount) {
      const studentspecs = []
      const partnerspecs = []
      const unispecs = []
      const specs = []

      if (selectedCategory === "") {
        setPartnersToRender(partnerspecs)
        setUniversitiesToRender(unispecs)
        setPortfoliosToRender(studentspecs)
        setSpecialismsToRender(specs)
        setIsFullView(false)
        return
      }
      searchList.map((item, index) => {
        try{
        if (item.group == "Student") {
          if (
            portfoliosData[item.originalIndex].student_profile.specialism.includes(selectedCategory) ||
            portfoliosData[item.originalIndex].student_profile.yearOfGraduation.toString().includes(selectedCategory)
          ) {
            studentspecs.push(portfoliosData[item.originalIndex])
          }
        } else if (item.group == "Partner") {
          if (partnersNode[item.originalIndex].PartnerInfo.tags.includes(selectedCategory)) {
            partnerspecs.push(partners[item.originalIndex])
          }
        } else if (item.group == "Graduation Year") {
          if (
            portfoliosData[item.originalIndex].student_profile.yearOfGraduation.toString().includes(selectedCategory)
          ) {
            studentspecs.push(portfoliosData[item.originalIndex])
          }
        }  
      }catch(e){
          // console.log(e)
        }
      })
      setPartnersToRender(partnerspecs.map(v => v.node))
      setPortfoliosToRender(studentspecs)
      setGraduationYearsToRender(studentspecs)
      setIsFullView(true)
    }
    return () => {
      mount = false
    }
  }, [selectedCategory])

  useEffect(() => {
    let mount = true
    if (mount) {
      const searchItemsArray = []
      {
        portfoliosData.map((item, index) => {
          searchItemsArray.push({
            name: item.student_profile.studentName,
            originalIndex: index,
            slug: item.slug,
            group: "Student",
          })
        })
      }
      {
        universitiesData.map((item, index) => {
          searchItemsArray.push({ name: item.title, originalIndex: index, slug: item.slug, group: "University" })
        })
      }
      {
        partnersNode.map((item, index) => {
          searchItemsArray.push({ name: item.title, originalIndex: index, slug: item.slug, group: "Partner" })
        })
      }
      {
        Sepcialisms.map((item, index) => {
          searchItemsArray.push({ name: item, originalIndex: index, slug: "", group: "Specialisms" })
        })
      }
      {
        studentYears.map((item, index) => {
          searchItemsArray.push({ name: item, originalIndex: index, slug: "", group: "Graduation Year" })
        })
      }

      setSearchList(searchItemsArray)
    }
    return () => {
      mount = false
    }
  }, [])

  function handleClickedSpecialism(value) {
    setSelectedCategory(value)
  }
  function handleClickedYear(value) {
    setSelectedCategory(value)
  }

  return (
    <Layout partners={partnersNode} title={"Graduate Fashion Week - Universities"}>
  
      <Container maxWidth={"lg"}>
        <Container maxWidth={"md"}>
          {!isFullView ? (
            <SearchBox type="text" id="lname" name="lname" placeholder="Type your search" onChange={handleUpdate} />
          ) : (
            ""
          )}
          <SearchContainer>
            {/* Text RESULTS =====================================================================================*/}
            {specialismsToRender.length >= 1 && !isFullView ? (
              <>
                <CategoryHeader> Categories</CategoryHeader>
                {specialismsToRender.map(v => (
                  <CategoryDiv onClick={() => handleClickedSpecialism(v)} key={"specialisms-to-render-"+v}>
                    <Listing>{v}</Listing>
                  </CategoryDiv>
                ))}
              </>
            ) : (
              ""
            )}

            {portfoliosToRender.length >= 1 && isPortfoliosToggle && !isFullView ? (
              <>
                <CategoryHeader> Portfolios</CategoryHeader>
                {portfoliosToRender.map((v, i) => (
                  <CategoryLink to={"/search-portfolios/" + v.slug} key={"portfolio-listing-" + i}>
                    <Listing>{v.title}</Listing>
                  </CategoryLink>
                ))}
              </>
            ) : (
              ""
            )}
            {/* This bit is commented out wierd. Had to add a few to catch all the bits */}
            {/* {partnersToRender.length >= 1 && !isFullView ? (
              <>
                <CategoryHeader> Partners</CategoryHeader>
                {/* <PartnerGrid partners={partnersToRender} />  */}
                {/* {partnersToRender.map((v, i) => (
                  <CategoryLink to={"/partners/" + v.slug} key={"partners-listing-" + i}>
                    <Listing>{v.title}</Listing>
                  </CategoryLink>
                ))} */}
              {/* </> */}
            {/* ) : ( */}
              {/* ""
            )}  */}

            {universitiesToRender.length >= 1 && !isFullView ? (
              <>
                <CategoryHeader>Universities</CategoryHeader>
                {/* <UniversityGrid unis={universitiesToRender}/> */}
                {universitiesToRender.map((v, i) => (
                  <CategoryLink to={"/universities/" + v.slug} key={"Universities-listing-" + i}>
                    <Listing>{v.title}</Listing>
                  </CategoryLink>
                ))}
              </>
            ) : (
              ""
            )}

            {graduationYearsToRender.length >= 1 && !isFullView ? (
              <>
                <CategoryHeader>Graduation Year</CategoryHeader>
                {/* <UniversityGrid unis={universitiesToRender}/> */}
                {graduationYearsToRender.map((v, i) => (
                  <CategoryDiv onClick={() => handleClickedYear(v)} key={"category-div-"+v.toString()}>
                    <Listing>{v.toString()}</Listing>
                  </CategoryDiv>
                ))}
              </>
            ) : (
              ""
            )}

            {/* FULL PAGE RESULTS =====================================================================================*/}
            {isFullView ? (
              <ToolbarContainer>
                <ToolbarHeading>
                  {" "}
                  Search results for <span>{selectedCategory}</span>
                </ToolbarHeading>
                <ToggleButtonRow>
                  <ToggleButton
                    label="All"
                    onChange={() => setToggleCategory({ partnerships: true, portfolios: true })}
                    checked={toggleCategory.partnerships && toggleCategory.portfolios}
                  />

                  <ToggleButton
                    label="Partnerships"
                    onChange={() => setToggleCategory({ partnerships: true, portfolios: false })}
                    checked={toggleCategory.partnerships && !toggleCategory.portfolios}
                  />
                  <ToggleButton
                    label="Portfolios"
                    onChange={() => setToggleCategory({ partnerships: false, portfolios: true })}
                    checked={!toggleCategory.partnerships && toggleCategory.portfolios}
                  />
                </ToggleButtonRow>
              </ToolbarContainer>
            ) : (
              ""
            )}
            {!portfoliosToRender.length >= 1 && !partnersToRender.length >= 1 && isFullView ? (
              <div>
                <h3 style={{ textAlign: "center", paddingTop: 20 }}>Sorry, no results found!</h3>
                <div onClick={() => setSelectedCategory("")} style={{ margin: "auto", width: 300 }}>
                  <ButtonSecondary customwidth={"300px"}>Back to Search</ButtonSecondary>
                </div>
              </div>
            ) : (
              ""
            )}
          </SearchContainer>
        </Container>
        <PortFolioContainer>
          {portfoliosToRender.length >= 1 && isFullView && toggleCategory.portfolios ? (
            <>
              <Container maxWidth={"md"}>
                <SearchSectionHeading>
                  <h2> Portfolios</h2>
                  <LinkContainer to={"/search-portfolios"}>
                    <ShowAllSpan>SEE ALL</ShowAllSpan>
                    <StyledRightArrow />
                  </LinkContainer>
                </SearchSectionHeading>
              </Container>

              <PortFolioCardGridContainer>
                <PortfoliosCardGrid portfolios={portfoliosToRender} />
              </PortFolioCardGridContainer>
              {/* <PortFolioCardGridContainer>
                <PortfoliosCardGrid portfolios={graduationYearsToRender} />
              </PortFolioCardGridContainer> */}
            </>
          ) : (
            ""
          )}
        </PortFolioContainer>
        <Container maxWidth={"md"}>
          <SearchContainer>
            {partnersToRender.length >= 1 && isFullView && toggleCategory.partnerships ? (
              <>
                <SearchSectionHeading>
                  <h2> Partners</h2>
                  <LinkContainer to={"/partnerships"}>
                    <ShowAllSpan>SEE ALL</ShowAllSpan>
                    <StyledRightArrow />
                  </LinkContainer>
                </SearchSectionHeading>
                <PartnerGrid partners={partnersToRender} />
              </>
            ) : (
              ""
            )}
          </SearchContainer>
        </Container>

        <SearchContainer />
        <SpaceHolder display={isFullView.toString()} />
      </Container>
    </Layout>
  )
}

export default Search

const LinkContainer = styled(Link)`
  color: ${props => props.theme.colors.turquoise};
  display: flex;
  flex-direction: row;
  align-items: center;
  /* min-width: 150px;
max-width: 150px; */
`
const SearchSectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const ShowAllSpan = styled.span`
  color: #0db0e7;
  font-size: 16px;
  font-weight: 500;
  min-width: 50px;
  line-height: 26px;
`
const StyledRightArrow = styled(RightArrow)``

const ToolbarContainer = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #cbcbcb;
  margin-bottom: 20px;
`
const ToggleButtonRow = styled.div`
  display: none; //as requested by raman
  flex-direction: row;
  margin: auto;
  width: 400px;
`

const ToolbarHeading = styled.h2`
  text-align: center;
  font-size: 32px;
  color: #4a4a4a;
  text-transform: none;
  padding-top: 100px;
  padding-top: 50px;
  & > span {
    color: #000000;
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
  }
`
const SearchContainer = styled.div`
  :last-of-type {
    margin-bottom: 100px;
  }
`
const CategoryHeader = styled.h6`
  padding-left: 5px;
  color: #000000;
  font-family: "Barlow Condensed";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 17px;
  margin-top: 20px;
  margin-bottom: 20px;
`



const CategoryLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  padding: 5px;
  display: block;
  &:hover {
    background-color: #ecf9fd;
    text-decoration: none;
    > span {
      color: black;
    }
  }
`

const CategoryDiv = styled.div`
  width: 100%;
  text-decoration: none;
  padding: 5px;
  display: block;
  &:hover {
    background-color: #ecf9fd;
    text-decoration: none;
    > span {
      color: black;
    }
  }
`
const SearchBox = styled.input`
  /* border: 1px solid red !important; */
  box-shadow: none !important;
  border: none !important;
  border-bottom: 2px solid black !important;
  font-size: 26px !important;
  padding-left: 5px !important;
  margin-bottom: 28px;
  margin-top: 200px;
`

const PortFolioCardGridContainer = styled.div`
  width: 100%;
  /* @media (min-width: ${props => props.theme.breakpoints.lg}) {
margin-left: -25%;
}
@media (min-width: ${props => props.theme.breakpoints.md}) {
margin-left: -10%;
} */
`
const Listing = styled.span`
  color: #666666;

  font-size: 22px;

  line-height: 26px;
`

const PortFolioContainer = styled.div`
  margin: 0 auto;

  width: 100%;
`
const SpaceHolder = styled.div`
  height: ${props => (props.display ? 0 : "400px")};
`

export const pageQuery = graphql`
  query search {
    allWpPartner {
      edges {
        node {
          id
          title
          slug
          PartnerInfo {
            tags
            partnershipIntro
            partnershipLogo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
    allWpUniversity {
      edges {
        node {
          id
          slug
          UniversityInfo {
            featuredimage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
            isInUk
            universityDescription
            universityLocationCityForSearch
          }
          title
        }
      }
    }
    allWpPortfolio {
      edges {
        node {
          id
          title
          slug
          student_profile {
            studentName
            specialism
            yearOfGraduation
            searchImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          student_portfolio {
            aboutMeHeading
          }
        }
      }
    }
  }
`
